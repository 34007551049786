import { AppBar, Button, Divider, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Skeleton, Toolbar, Typography, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.css"
import { userContext } from "Components/User";
import LogoutIcon from '@mui/icons-material/Logout';
import UserAvatar from "Components/User/avatar";
import { useNavigate } from "react-router-dom";
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import Language from "Components/Language";


const Header = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const uContext = useContext(userContext);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToSingIn = () => {
    navigate("/signin");
  }

  const navigateHome = () => {
    navigate(`/${i18n.language}`);
  }

  const navigateSettings = () => {
    handleClose();
    navigate(`/${i18n.language}/settings`);
  }

  const onSignOut = () => {
    // console.log("onSignOut: ", signOut, "userPass:", userPass, "user: ", user);
    if (uContext.signOut) {
      uContext.signOut();
      navigateToSingIn();
    }
    setAnchorEl(null);
  };

  const getUserPanelItems = () => (
    [
      <IconButton
        title={t("global.search")}
        key="rocket-icon"
        onClick={() => navigate(`/${i18n.language}/search`)}
      >
        <RocketLaunchOutlinedIcon style={{
          // opacity: 0.7,
          color: theme.palette.primary.main
        }} />
      </IconButton>,
      <IconButton
        key="AccountButton"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <UserAvatar />
      </IconButton>,
    ]
  )

  const getLoadingUserItems = () => {
    return (
      [
        <Skeleton sx={{ mr: "10px", width: 120, height: "3.5em" }} key="skeleton1" />,
        <IconButton key="skeleton2">
          <Skeleton variant="circular" width={32} height={32} />
        </IconButton>
      ]
    )
  }

  const getUnathorizedUserItems = () => (
    [
      <Button key="signIn" variant="text" href="/signin" sx={{ fontWeight: "bold", mr: "10px" }}>
        {t("global.signIn")}
      </Button>,
      <Button key="try" variant="contained" href="/signin" sx={{ fontWeight: "bold", color: "white" }}>
        {t("header.try")}
      </Button>,
    ]
  )

  return (
    <AppBar
      id="app-bar"
      position="relative"
      color="default"
      elevation={0}
      sx={{
        // backgroundColor: (theme) => theme.palette.background.default,
        backgroundColor: "transparent",
      }}
    >
      <Toolbar id="app-bar" key="toolbar" sx={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>
        <Grid container>
          <Grid xs onClick={navigateHome}
            item
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
            key={"company name panel"}
          >
            <IconButton sx={{
              // box-shadow: 0 0 35px rgba(255, 136, 0, 0.8);
              // transition: box-shadow 0.3s ease;
              backgroundColor: "transparent",
              "&:hover": {
                boxShadow: "0 0 35px rgba(255, 136, 0, 0.8)",
                transition: "box-shadow 0.3s ease",
                backgroundColor: "transparent"
              }
            }}>
              <img src={'/i-48.png'} alt="logo" width={24} height={24} key={"logo icon"} />
            </IconButton>

            <Typography
              // variant="h6"
              key={"company name"}
              component="div"
              color="textPrimary"
              sx={{
                fontWeight: "bold", margin: "0 5px 0 5px",
                fontSize: { sm: "x-large", xs: "small" },
                // wordSpacing: { xs: "100vw", sm: 0 },
                width: { sm: "auto", xs: "5em" },
                lineHeight: { xs: "1em", sm: "1em" },
                backgroundImage: `linear-gradient(90deg in hsl, ${theme.palette.mode === 'dark' ? 'lightskyblue, dodgerblue' : 'royalblue, mediumblue'} )`,
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                color: 'transparent',
              }}
            >
              {t("main.header")}
            </Typography>
          </Grid>
          {uContext.authStatus
            && uContext.authStatus !== "configuring"
            && (
              <Grid item>
                <Language />
              </Grid>)
          }
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            {
              uContext.user ?
                getUserPanelItems() :
                !uContext.authStatus || uContext.authStatus === "configuring" ?
                  getLoadingUserItems() :
                  getUnathorizedUserItems()
            }
          </Grid>
        </Grid>
      </Toolbar>
      <Menu key="usersMenu"
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem key="name" selected={false}
          sx={{
            "&.MuiButtonBase-root:hover": {
              bgcolor: "transparent"
            },
          }}>
          <ListItemText sx={{ textDecoration: "underline" }}>{uContext.name ?? uContext.user?.username}</ListItemText>
        </MenuItem>
        <MenuItem onClick={navigateSettings}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText>{t("header.settings")}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => onSignOut()}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>{t("header.signOut")}</ListItemText>
        </MenuItem>
      </Menu>
    </AppBar >
  )
};

export default Header;